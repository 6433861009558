import React from "react";
import "./Progress.css";

function Progress() {
  
  return (
    <div className="Progress-Container">
      <h3 style={{ textAlign: 'left', color: 'white' }}>Leaderboard</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Badges</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Errors</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Conversation Starters</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Vocabulary</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Grammar</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Culture</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Text</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Conversation Topics</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Idiom</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Persuasion</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Fluency</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Logic</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
      <h3 style={{ textAlign: 'left', color: 'white' }}>Conversation Closers</h3>
      <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
    </div>
  );
}

export default Progress;