// NotFound.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css"; // Import a CSS file for styling



const NotFound = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <div className="NOFO-not-found-container">
      <h2 className="NOFO-not-found-text">404 - Page Not Found</h2>
      <p>So sorry, so sorry, so sorry!</p>
      <p>But, the page you are looking for does not exist.</p>
      <p>I repeat, the page you are looking for does not exist.</p>
      <p>Please try a different, live, ispeakwell.ca URL.</p>
      <p className="NOFO-Paragraph">Such as{", "}
        <a className="NOFO-LINK" target="_blank" rel="noreferrer" href="https://ispeakwell.ca/tokens">
        ispeakwell.ca/tokens
        </a>{""}.</p>
      <div className="NOFO-links-section">
        <a onClick={() => navigateTo("/about")} href="/about">
          About
        </a>
        <a onClick={() => navigateTo("/careers")} href="/careers">
          Careers
        </a>
        <a onClick={() => navigateTo("/tokens")} href="/tokens">
          Tokens
        </a>
        <a onClick={() => navigateTo("/contactus")} href="contactus">
          Contact Us
        </a>
        <a onClick={() => navigateTo("/login")} href="login">
          Login
        </a>
      </div>
    </div>
  );
};

export default NotFound;
