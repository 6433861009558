import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Logic.css";

function Logic({ level }) {
  const [logicData, setLogicData] = useState([]);
  const [filteredLogic, setFilteredLogic] = useState([]);

  // Fetch and parse the conversation Logic data
  useEffect(() => {
    Papa.parse("/data/Logic.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setLogicData(transformedData); // Store all conversation Logic data
        
      },
    });
  }, []);

  // Filter Logic based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredLogic(
        logicData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredLogic([]); // Clear the list if no level is selected
    }
  }, [level, logicData]);

  return (
    <div>
      {filteredLogic.length > 0 ? (
        filteredLogic.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Logic;
