import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Grammar.css";

function Grammar({ level }) {
  const [grammarData, setGrammarData] = useState([]);
  const [filteredGrammar, setFilteredGrammar] = useState([]);
  const [isMounted, setIsMounted] = useState(true); // Flag to track if component is mounted

  // Fetch and parse the conversation Grammar data
  useEffect(() => {
    // Set isMounted to true when the component mounts
    setIsMounted(true);

    // Cleanup function to set isMounted to false when the component unmounts
    return () => setIsMounted(false);
  }, []); // This runs only once on component mount

  useEffect(() => {
    let isMounted = true;
  
    Papa.parse("/data/GrammarbyCEFRLevel.csv", {
      download: true,
      complete: (results) => {
        if (isMounted) {
          const data = results.data.slice(1); // Skip the header row
          const transformedData = data.map((row) => ({
            Level: row[0],
            Topic: row[2],
            Description: row[3],
          }));
          setGrammarData(transformedData); // Store all conversation Grammar data
        }
      },
    });
  
    return () => {
      isMounted = false; // Cleanup: prevent state update after unmount
    };
  }, []); // Runs once on mount, no need for `isMounted` dependency
  

  // Filter Grammar based on the level prop
  useEffect(() => {
    if (level) {
      const filtered = grammarData.filter((item) => item.Level === level);
      setFilteredGrammar(filtered);
    } else {
      setFilteredGrammar([]); // Clear the list if no level is selected
    }
  }, [level]); // Only run when `level` changes, not when `grammarData` changes
  
  return (
    <div>
      {filteredGrammar.length > 0 ? (
        filteredGrammar.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Grammar;
