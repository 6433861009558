import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Fluency.css";

function Fluency({ level }) {
  const [fluencyData, setFluencyData] = useState([]);
  const [filteredFluency, setFilteredFluency] = useState([]);

  // Fetch and parse the conversation Fluency data
  useEffect(() => {
    Papa.parse("/data/Fluency.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setFluencyData(transformedData); // Store all conversation Fluency data
        
      },
    });
  }, []);

  // Filter Fluency based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredFluency(
        fluencyData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredFluency([]); // Clear the list if no level is selected
    }
  }, [level, fluencyData]);

  return (
    <div>
      {filteredFluency.length > 0 ? (
        filteredFluency.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Fluency;
