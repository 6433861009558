import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./End.css";

function End({ level }) {
  const [endersData, setEndersData] = useState([]);
  const [filteredEnders, setFilteredEnders] = useState([]);

  // Fetch and parse the conversation Enders data
  useEffect(() => {
    Papa.parse("/data/Enders.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setEndersData(transformedData); // Store all conversation Enders data
        
      },
    });
  }, []);

  // Filter Enders based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredEnders(
        endersData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredEnders([]); // Clear the list if no level is selected
    }
  }, [level, endersData]);

  return (
    <div>
      {filteredEnders.length > 0 ? (
        filteredEnders.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default End;
