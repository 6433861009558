import "./VB.css";
import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import { VoiceBotStateContext } from "./VoiceBotStateContext";
import Spinner from "./Spinner";
import { getAuth } from "firebase/auth";
import { db } from "../utils/firebase";
import { doc, updateDoc, getDoc } from "firebase/firestore"; //collection, addDoc, 
import { useAuth } from "./AuthContext";
import VoiceBotIframe from "./VoiceBotiFrame";
import VoiceBotToolsIframe from "./VoiceBotToolsIFrame";
import axios from "axios";
import YouTube from "react-youtube";
import BackofMonitorImage from "./BackofMonitor.png"; // Importing the image
import SeattoRideImage from "./SeattoRide.png"; // Importing the image
import Plant1 from "./Plant1.png";
import Plant2 from "./Plant2.png";
import StudioMic from "./StudioMic.png";
import Bookshelf1 from "./Bookshelf1.png";
import Curtains from "./Curtains.png";
import StudioMonitor from "./StudioMonitor.png";
import ComfyChair from "./ComfyChair.png";
import StageLights from "./StageLights.png";
import WordCloud from 'react-wordcloud';
import Talk from "./Talk";
import Begin from "./Begin";
import Progress from "./Progress";
import Grammar from "./Grammar";
import Culture from "./Culture";
import Text from "./Text";
import Idiom from "./Idiom";
import Fluency from "./Fluency";
import Persuasion from "./Persuasion";
import Logic from "./Logic";
import Vocabulary from "./Vocabulary";
import { logout } from "../utils/firebase";
import End from "./End";
import Overlay from "./overLay";
import GoogleSearch from "./GoogleSearch";
  
function VB() {
  const [videoId, setVideoId] = useState('tv6W0Nv5ev0'); // Default video ID
  const [playlistVideos, setPlaylistVideos] = useState([]);
  const [isPlaylistVisible, setIsPlaylistVisible] = useState(true);
  const [boxVisible, setBoxVisible] = useState(false);
  const [boxTitle, setBoxTitle] = useState('');
  const [transcript, setTranscript] = useState(''); // State for the transcript
  const [content, setContent] = useState('');
  const [player, setPlayer] = useState(null); // Store the YouTube player instance
  const API_KEY = process.env.REACT_APP_GOOGLE_CLOUD_API_KEY2;
  const PLAYLIST_ID = 'PLblaVxcRe39xzkN6H28tnHOJt_fhfZzqo';
  const [vocabulary, setVocabulary] = useState([]);
  const [definitions, setDefinitions] = useState({});
  const [loadingWord, setLoadingWord] = useState(null);
  const [openAIResponse, setOpenAIResponse] = useState('');
  const [wordCloudWidth, setWordCloudWidth] = useState(2);
  const [wordCloudHeight, setWordCloudHeight] = useState(75);
  const [userLevel, setUserLevel] = useState('A1');
  const [tokens, setTokens] = useState(0);
  const { userRole } = useAuth();
  const { voiceBotState, setVoiceBotState } = useContext(VoiceBotStateContext);
  const userSpeechRef = useRef(null);
  const recognition = useMemo(() => {
    const speechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    return new speechRecognition();
  }, []);
  const navigate = useNavigate();
  const isListeningRef = useRef(false);
  //const voiceBotTextRef = useRef("");
  //const [isAnalyzing, setIsAnalyzing] = useState(false);
  //const [showResults, setShowResults] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [typedChars, setTypedChars] = useState(0);
  const [spokenChars, setSpokenChars] = useState(0);
  const [prevLength, setPrevLength] = useState(0);
  const [prevVocabularyLevel, setPrevVocabularyLevel] = useState("");
  const [activeTab, setActiveTab] = useState('vocabulary');
  const [activeTalkTab, setActiveTalkTab] = useState('AI')
  const [activeFluencyTab, setActiveFluencyTab] = useState('FM')
  const [activeCultureTab, setActiveCultureTab] = useState('Search');
  const [login, setLogin] = useState(false);
  const [username, setUsername] = useState('Stephane');
  const [room, setRoom] = useState('Vocabulary');  // Default room
  const [visits, setVisits] = useState(1);
  const [totalTimeOnSite, setTotalTimeOnSite] = useState(3500);
  const [phonemesPerMinute, setPhonemesPerMinute] = useState('0ppm');
  const [wordsPerMinute, setWordsPerMinute] = useState('0wpm');
  const [totalWordsAcquired, setTotalWordsAcquired] = useState(0);
  const [newWordsAcquired, setNewWordsAcquired] = useState(0);
  const [twoPhonemeCombinations, setTwoPhonemeCombinations] = useState(0);
  const [vocab, setVocab] = useState(0);
  const [grammar, setGrammar] = useState(0);
  const [idiom, setIdiom] = useState(0);
  const [persuasion, setPersuasion] = useState(0);
  const [logic, setLogic] = useState(0);
  const [culture, setCulture] = useState(0);
  const [fluency, setFluency] = useState(0);
  const [talk, setTalk] = useState(0);
  const [progress, setProgress] = useState(0);
  const [testsCompleted, setTestsCompleted] = useState(0);
  const [aggregatedScore, setAggregatedScore] = useState(0);
  const [lastInteraction, setLastInteraction] = useState('');
  //const [fluenctCBits, setFluentCBits] = useState(5000);
  //const [iframeReady, setIframeReady] = useState(false);
  const playerRef = useRef(null);
  const [numTrials, setNumTrials] = useState(3); // Default trials set to 3

    const addInteraction = (newInteraction) => {
      setLastInteraction(newInteraction);
    };

    // List of available rooms
    const rooms = [
      'Vocabulary', 'Grammar', 'Idiom', 'Persuasion',
      'Culture', 'Text', 'Fluency', 'Logic', 'Sources'
    ];
  
    // Handle login form submission
    const handleLoginSubmit = (event) => {
      event.preventDefault();
      if (username) {
        setLogin(true);  // Mark as logged in after form submission
      }
    };

    const leaveRoom = () => {
      setLogin(false);
      setUsername("");
      setRoom("Vocabulary");
      addInteraction('Left chat room.');
    }
  
    // Construct the iframe URL with username and room parameters
    const iframeSrc = login 
  ? `https://chatapp.ispeakwell.ca/chat.html?username=${encodeURIComponent(username)}&room=${encodeURIComponent(room)}&platform=iSpeakWell`
  : '';

  
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    addInteraction(tab + ' trainer opened.');
  };

  const handleTalkTabChange = (tab) => {
    setActiveTalkTab(tab);
    addInteraction('Talk ' + tab + ' function opened.');
  };

  const handleFluencyTabChange = (tab) => {
    setActiveFluencyTab(tab);
    addInteraction('Fluency ' + tab + ' function opened.');
  };

  const handleCultureTabChange = (tab) => {
    setActiveCultureTab(tab);
    addInteraction('Culture ' + tab + ' function opened.');
  };

  useEffect(() => {
    let isMounted = true;
  
    const fetchTokens = async () => {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const userRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists() && isMounted) {
            setTokens(userDoc.data().tokens || 0);
          }
        } catch (error) {
          console.error("Error fetching token count: ", error);
        }
      }
    };
  
    fetchTokens();
  
    return () => {
      isMounted = false;
    };
  }, []);
  

  const deductToken = async () => {
    const newTokenCount = tokens - 1;
    setTokens(newTokenCount); // Update the local state

    // Update the database
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { tokens: newTokenCount });
      }
    } catch (error) {
      console.error("Error updating token count: ", error);
      // Optionally, handle any errors, like reverting the local state change
    }
  };

  // Handle level selection and filter data
  const handleLevelChange = (event) => {
    const level = event.target.value;
    setUserLevel(level);
    if (boxTitle === "Vocabulary") {
      setPrevVocabularyLevel(event.target.value);
    }
    addInteraction('User level changed to ' + level + '.');
  };

  useEffect(() => {
    if (boxTitle !== "Vocabulary" && (userLevel === "M1" || userLevel === "M2")) {
      // Switch to C2 when leaving Vocabulary if M1 or M2 was selected
      setUserLevel("C2");
      addInteraction('Expansion Master Vocabulary level' + userLevel + ' selected.');
    } else if (boxTitle === "Vocabulary" && prevVocabularyLevel) {
      // Restore previous level for Vocabulary
      setUserLevel(prevVocabularyLevel);
    }
  }, [boxTitle, prevVocabularyLevel, userLevel]);

  const stopwords = new Set([
    ".", ";", "-", "'", "'re", "'ve", "'ll", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
    , "a", "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "'m", "about", "above", "after", "again", "against", "all", "am", "an", "and", "any", "are", "aren't", "aren't", "aren't", 
    "aren't", "aren't", "aren't", "aren't", "aren't", "as", "at", "be", "because", "been", "before", "being", "below", "between", 
    "both", "but", "by", "can't", "cannot", "could", "couldn't", "couldn't", "did", "didn't", "do", "does", "doesn't", "doing", "don't", 
    "down", "during", "each", "few", "for", "from", "further", "had", "has", "hadn't", "having", "he", "he'd", "he'll", "he's", "her", 
    "here", "here's", "hereafter", "hereby", "herein", "here's", "hers", "herself", "hers", "him", "his", "how", "how'd", "how'll", "how's", "howsoever", 
    "i", "i'd", "i'll", "i'm", "i've", "i", "it", "i'd", "it'll", "it's", "it's", "it", "its", "itself", "let's", "more", "most", "most", 
    "me", "my", "myself", "myself", "on", "and", "of", "once", "only", "or", "other", "ought", "our", "ours", "ourselves", "out", 
    "over", "own", "same", "shall", "shan't", "she", "she'd", "she'll", "she's", "should", "shouldn't", "so", "so's", "some", "such", 
    "than", "that", "that'll", "that's", "that've", "the", "the'd", "the'll", "the're", "the've", "their", "theirs", "themselves", 
    "then", "there", "there'd", "there'll", "there're", "there've", "therefore", "these", "they", "they'd", "they'll", "they're", 
    "they've", "this", "this's", "those", "through", "to", "too", "under", "until", "up", "very", "was", "wasn't", "we", "we'd", 
    "we'll", "we're", "we've", "we", "were", "weren't", "what", "what'd", "what'll", "what's", "what've", "whatever", "when", "when'd", 
    "when'll", "when's", "when've", "where", "where'd", "where'll", "where's", "where've", "whether", "which", "which'd", "which'll", 
    "which's", "while", "while'd", "while'll", "while's", "who", "who'd", "who'll", "who's", "who've", "whoever", "whom", "whom'd", 
    "whom'll", "whom's", "why", "why'd", "why'll", "why's", "why've", "why", "you", "you'd", "you'll", "you're", "you've", 
    "you", "your", "yours", "yourself", "yourselves", "yourself", "yourselves", "with", "won't", "would", "wouldn't", "you", 
    "you'd", "you'll", "you're", "you've", "yourselves", "yours", "yourself", "yourselves", "he's", "she's", "it's", "i'm", "i've", 
    "they're", "we're", "you're", "i've", "he'll", "she'll", "we'll", "they'll", "wouldn't", "couldn't", "shouldn't", "isn't", 
    "aren't", "weren't", "hasn't", "haven't", "doesn't", "didn't", "wasn't", "isn't", "was", "were", "be", "been", "being", "having", 
    "on", "in", "to", "from", "with", "at", "by", "for", "about", "as", "into", "through", "over", "after", "before", "during", "'s", "n't", "ca", "if", "wo", "is"
]);

// Function to prepare word cloud data
const prepareWordCloudData = (vocabulary, minFrequency = wordCloudWidth, maxFrequency = wordCloudHeight) => {
  return Object.keys(vocabulary)
    .filter(word => !stopwords.has(word) && vocabulary[word].length >= minFrequency && vocabulary[word].length <= maxFrequency) // Exclude stopwords and words below the threshold
    .map((word) => ({
      text: word,
      value: vocabulary[word].length, // Using the count of timestamps as frequency
    }));
};

  // Memoize vocabulary data to avoid unnecessary re-fetching
  const memoizedVocabulary = useMemo(() => vocabulary, [vocabulary]);
  const wordCloudData = prepareWordCloudData(memoizedVocabulary);

  useEffect(() => {
    let isMounted = true;
  
    const fetchVocabularyData = async (videoId) => {
      try {
        const response = await axios.get(`https://transcript.ispeakwell.ca/transcript?video_id=${videoId}`);
        const { nlp_data, transcript } = response.data;
  
        const vocabularyWithTimestamps = nlp_data?.unique_tokens.reduce((acc, token) => {
          const tokenTimestamps = [];
          transcript.split('\n').forEach((line) => {
            const regex = /^\[(\d+(\.\d+)?)s\]\s*(.*)$/;
            const match = line.match(regex);
            if (match && match[3].includes(token)) {
              tokenTimestamps.push(parseFloat(match[1]));
            }
          });
          acc[token] = tokenTimestamps;
          return acc;
        }, {});
  
        if (isMounted) {
          setVocabulary(vocabularyWithTimestamps);
        }
      } catch (error) {
        console.error("Error fetching vocabulary:", error);
      }
    };
  
    fetchVocabularyData(videoId);
  
    return () => {
      isMounted = false;
    };
  }, [videoId]);
  

  // Fetch playlist videos on component mount
  useEffect(() => {
    let isMounted = true;
  
    const fetchPlaylistVideos = async () => {
      let nextPageToken = '';
      let allVideos = [];
  
      do {
        const response = await fetch(`https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=${PLAYLIST_ID}&maxResults=50&key=${API_KEY}${nextPageToken ? `&pageToken=${nextPageToken}` : ''}`);
        const data = await response.json();
        allVideos = allVideos.concat(data.items);
        nextPageToken = data.nextPageToken;
      } while (nextPageToken);
  
      if (isMounted) {
        setPlaylistVideos(allVideos);
      }
    };
  
    fetchPlaylistVideos();
  
    return () => {
      isMounted = false;
    };
  }, [API_KEY]);
  

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);
  

  const handlePlayerReady = (event) => {
    setPlayer(event.target);
    playerRef.current = event.target;
    handlePlayClick();
  };

  const handlePlayerStateChange = (event) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
    }
  };

  const handleInputChange = (e) => {
    setVideoId(e.target.value);
  };

  const handlePlayClick = async () => {
    if (videoId) {
      const idRegex = /(?:youtube\.com(?:[^/\n\s]+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^&\n]{11})/;
      const match = videoId.match(idRegex);
      const extractedVideoId = match ? match[1] : videoId;
      setVideoId(extractedVideoId);
      await fetchTranscript(extractedVideoId);
    }
  };

  const handlePlay = () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
    }
    addInteraction('Video playing.');
  };
  
  const handlePause = () => {
    if (playerRef.current) {
      playerRef.current.pauseVideo();
    }
    addInteraction('Video paused.');
  };
  
  const handleRepeat500ms = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 0.75, 0)); // Rewind by 500ms
    }
    addInteraction('Video quick repeat practice.');
  };

  const handleRepeat2s = () => {
    if (playerRef.current) {
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 2.25, 0)); // Rewind by 2000ms
    }
    addInteraction('Video long repeat practice.');
  };

  let repeatInterval = null; // Declare the repeatInterval variable outside the function

  const handleRepeatCycle = () => {
    addInteraction('Video repeat cycle training initiated.');
    if (playerRef.current) {
      const totalTrials = numTrials; // Number of trials selected by the user
      let trialCount = 0;
  
      // Immediately rewind 1 second when the button is pressed
      const currentTime = playerRef.current.getCurrentTime();
      playerRef.current.seekTo(Math.max(currentTime - 1, 0)); // Rewind by 1 second
      playerRef.current.playVideo(); // Start playing from the rewound point
  
      // Start the repeat cycle immediately
      repeatInterval = setInterval(() => {
        if (trialCount >= totalTrials) {
          clearInterval(repeatInterval); // Stop after the specified number of trials
          return;
        }
  
        // Step 1: Play the same 1 second of audio from the current position
        playerRef.current.seekTo(Math.max(currentTime - 1, 0)); // Ensure we start from the same spot
        playerRef.current.playVideo(); // Play audio unmuted for 1 second
  
        // Step 2: After 1 second of unmuted play, mute the video for 2 seconds
        setTimeout(() => {
          playerRef.current.mute(); // Mute the video
  
          setTimeout(() => {
            // Step 3: After 2 seconds of mute, play the next trial (unmuted) from the same spot
            playerRef.current.seekTo(Math.max(currentTime - 1, 0)); // Rewind to the same point
            playerRef.current.unMute(); // Unmute the video
            playerRef.current.playVideo(); // Play audio unmuted for the next trial
          }, 2250); // Wait for 2 seconds while muted
        }, 1250); // Wait for 1 second of unmuted play
  
        trialCount++; // Increment trial count
      }, 5000); // Repeat cycle every 4 seconds (1 second unmuted, 2 seconds muted)
    }
  };
  

  // Stop the repeat cycle when the stop button is pressed
  const handleStop = () => {
    if (repeatInterval) {
      clearInterval(repeatInterval); // Clear the interval and stop the repeat cycle
      repeatInterval = null; // Reset the repeatInterval variable
    }

    // Optionally, stop the video and reset it to the current position
    if (playerRef.current) {
      playerRef.current.pauseVideo(); // Pause the video
      playerRef.current.seekTo(0); // Optionally, reset to the beginning
    }
  };

  const startTimer = () => {
    if (startTime === null) {
      setStartTime(new Date());
    }
  };

  // When submitting an answer
  const stopTimer = () => {
    const currentEndTime = new Date();
    const timeTaken = currentEndTime - startTime; // Calculate time taken
    // If you need to update the state or use this value in your component, do it here
    console.log("Time taken:", formatTime(timeTaken > 0 ? timeTaken : 0));
    setStartTime(null);
    return timeTaken > 0 ? timeTaken : 0; // Ensure non-negative value
  };

  const fetchTranscript = async (videoId) => {
    try {
      const response = await axios.get(`https://transcript.ispeakwell.ca/transcript?video_id=${videoId}`);
    
      if (response.data) {
        const { nlp_data, transcript } = response.data; // Destructure the response to get nlp_data
    
        if (transcript) {
          const formattedTranscript = format_transcript_with_start_times(transcript);
    
          // Prevent setting the same value for transcript
          if (formattedTranscript !== transcript) {
            setTranscript(formattedTranscript);
          }
        } else {
          setTranscript("No transcript available for this video.");
        }
    
        // Capture unique_tokens and set it to vocabulary
        if (nlp_data && nlp_data.unique_tokens) {
          const vocabularyWithTimestamps = nlp_data.unique_tokens.reduce((acc, token) => {
            // Find all timestamps for the token in the transcript
            const tokenTimestamps = [];
            transcript.split('\n').forEach((line, index) => {
              const regex = /^\[(\d+(\.\d+)?)s\]\s*(.*)$/;
              const match = line.match(regex);
              if (match && match[3].includes(token)) {
                tokenTimestamps.push(parseFloat(match[1]));
              }
            });
            acc[token] = tokenTimestamps;
            return acc;
          }, {});
          setVocabulary(vocabularyWithTimestamps); // Set vocabulary with timestamps
        } else {
          setVocabulary({}); // If no unique_tokens are found, set an empty object
        }
      } else {
        setTranscript("Error: Invalid response format.");
        setVocabulary({}); // No tokens available
      }
    } catch (error) {
      console.error("Error fetching transcript:", error);
      setTranscript("Error fetching transcript.");
      setVocabulary({}); // In case of error, set vocabulary as empty
    }
  };
  
  
  const handleVocabularyClick = useCallback(
    async (word) => {
      if (definitions[word]) {
        return;
      }
      setLoadingWord(word);
      try {
        const response = await axios.get(`https://transcript.ispeakwell.ca/word_definition?word=${word}`);
        const wordDefinitions = response.data.definitions;
        setDefinitions((prevDefs) => ({
          ...prevDefs,
          [word]: wordDefinitions,
        }));
      } catch (error) {
        console.error("Error fetching definition:", error);
        setDefinitions((prevDefs) => ({
          ...prevDefs,
          [word]: ["Definition not found or error occurred."],
        }));
      } finally {
        setLoadingWord(null);
      }
    },
    [definitions]
  );

  const handleVideoClick = async (id) => {
    setVideoId(id);
    await fetchTranscript(id); // Fetch the transcript for the clicked video
  };

  const togglePlaylistVisibility = () => {
    setIsPlaylistVisible(prev => !prev);
  };

  const handleButtonClick = (title) => {
    if(title !== "Begin" && title !== "End"){
      handleSubmitOpenAI(title);
    }
    if (title === "Text") {
      if (boxVisible && boxTitle === "Transcript") {
        setBoxVisible(false);
      } else {
        setBoxTitle("Transcript");
        setContent(transcript);
        setBoxVisible(true);
      }
    } else {
      if (boxVisible && boxTitle === title) {
        setBoxVisible(false);
      } else {
        setBoxTitle(title);
        setBoxVisible(true);
        switch (title) {
          case 'Vocabulary':
            setContent(vocabulary); // Set vocabulary to content
            return;
          // Other cases for different content
          default:
            return;
        }
      }
    }
  };
  
  const handleHideClick = () => {
    setBoxVisible(false);
  };

  function formatTimestamp(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    return `${hours > 0 ? `${hours}:` : ''}${minutes < 10 && hours > 0 ? `0${minutes}` : minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  const handleTimestampClick = (timestamp) => {
    if (player) {
      player.seekTo(timestamp-1, true);
    }
  };

  const formatTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // Formatting to ensure two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    if (hours > 0) {
      return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
    } else if (minutes > 0) {
      return `${formattedMinutes}m ${formattedSeconds}s`;
    } else {
      return `${formattedSeconds}s`;
    }
  };

  function format_transcript_with_start_times(transcriptText) {
    const lines = transcriptText.split('\n');

    return lines
      .map(line => {
        const regex = /^\[(\d+(\.\d+)?)s\]\s*(.*)$/;
        const match = line.match(regex);

        if (match) {
          const timestamp = parseFloat(match[1]);
          const text = match[3];
          const formattedTimestamp = formatTimestamp(timestamp);

          return (
            <div 
              key={timestamp}
              className="transcript-line" 
              style={{ margin: '8px 0', cursor: 'pointer' }}
              onClick={() => handleTimestampClick(timestamp)}
            >
              <span style={{ fontWeight: 'bold' }}>[{formattedTimestamp}]</span> {text}
            </div>
          );
        }
        return null; 
      })
      .filter(line => line);  // Remove null entries
  }

  const opts = {
    height: '250',
    width: '445',
    playerVars: {
      autoplay: 0, // Video does not autoplay by default
      controls: 1,
      modestbranding: 1,
      enablejsapi: 1,
      origin: window.location.origin,
    },
  };

 const handleSubmitOpenAI = async (title) => {
    /* if (tokens > 0) {
      if (
        window.confirm(
          "This action will require 1 Token.  Do not leave this page during the process."
        )
      ) {*/
        addInteraction('Opened ' + title + ' developer tool.');
        const dataToSend = JSON.stringify({ transcript: title });
        console.log("Data Sent to OpenAI from Browser:", dataToSend);
        let data; // Declare data at a higher scope
        let OAIURL = "";
        console.log(title);
        switch(title){
          case "Vocabulary": 
            OAIURL = "https://fluency.ispeakwell.ca/api/vocabulary";
            break;
          case "Grammar": 
            OAIURL = "https://fluency.ispeakwell.ca/api/grammar";
            break;
          case "Talk": 
            OAIURL = "https://fluency.ispeakwell.ca/api/talk";
            break;
          case "Idiom": 
            OAIURL = "https://fluency.ispeakwell.ca/api/idiom";
            break;
          case "Persuasion": 
            OAIURL = "https://fluency.ispeakwell.ca/api/persuasion";
            break;
          case "Culture": 
            OAIURL = "https://fluency.ispeakwell.ca/api/culture";
            break;
          case "Text": 
            OAIURL = "https://fluency.ispeakwell.ca/api/text";
            break;
          case "Progress": 
            OAIURL = "https://fluency.ispeakwell.ca/api/progress";
            break;
          case "Fluency": 
            OAIURL = "https://fluency.ispeakwell.ca/api/fluency";
            break;
          case "Logic": 
            OAIURL = "https://fluency.ispeakwell.ca/api/logic";
            break;
          default: break;
        }
        console.log(OAIURL);
        try {
          const response = await fetch(
            OAIURL,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: dataToSend,
            }
          );

          data = await response.json(); // Assign data here
          setOpenAIResponse(data.message); // Assuming 'message' contains the response from OpenAI
          console.log("OpenAI response: ", data.message);
          //deductToken();
        } catch (error) {
          console.error("Error submitting to API:", error);
        }
        if (data) {
          //await parseAndSaveResponses(data.message);
        }
      } 
      
      /*else {
        return;
      }
    } else {
      alert(
        "Your token balance is zero.  Please purchase tokens.  Then, try again."
      );
      return;
    }
  };*/

  const handlewordCloudWidthSliderChange = (event) => {
    setWordCloudWidth(event.target.value); // Update the state with the new value
  };
  
  const handlewordCloudHeightSliderChange = (event) => {
    setWordCloudHeight(event.target.value); // Update the state with the new value
  };

  const Dashboard = () => {
    navigate("/resumerevisor");
  };

  const InterViewPractice = () => {
    navigate("/interview-practice");
  };

  const ConversationPractice = () => {
    navigate("/conversationPractice");
  };

  const Purchase = () => {
    navigate("/purchase");
  };

  const admin = () => {
    navigate("/admin");
  };

  const TermsofService = () => {
    navigate("/termsofservice");
  };

  const Privacy = () => {
    navigate("/privacypolicy");
  };

  const Logout = async () => {
      addInteraction('Logged out.');
      await logout();
      navigate("/login");
    };

  const blank = () =>{
    return;
  }

  const [isOpen, setIsOpen] = React.useState(false);
  
    const toggleDrawer = (open) => (event) => {
      if (
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }
      setIsOpen(open);
    };
  
    const list = () => (
      <div
        role="presentation"
        onClick={toggleDrawer(false)}
        onKeyDown={toggleDrawer(false)}
      >
        <List>
          <ListItemButton onClick={Purchase}>
            <ListItemText primary={`Tokens: ${tokens}`} />
          </ListItemButton>
          <ListItemButton onClick={Dashboard}>
            <ListItemText primary="Resume Revisor" />
          </ListItemButton>
          <ListItemButton onClick={InterViewPractice}>
            <ListItemText primary="Interview Practice" />
          </ListItemButton>
          <ListItemButton onClick={ConversationPractice}>
            <ListItemText primary="Conversation Practice" />
          </ListItemButton>
          {userRole === "admin" && (
            <>
              <ListItemButton onClick={blank}>
                <ListItemText primary="Test" />
              </ListItemButton>
              <ListItemButton onClick={blank}>
                <ListItemText primary="Reset" />
              </ListItemButton>
            </>
          )}
          <ListItemButton onClick={admin}>
            <ListItemText primary="Admin" />
          </ListItemButton>
          <ListItemButton onClick={Logout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
          <ListItemButton onClick={TermsofService}>
            <ListItemText primary="Terms of Service" />
          </ListItemButton>
          <ListItemButton onClick={Privacy}>
            <ListItemText primary="Privacy" />
          </ListItemButton>
        </List>
      </div>
    );

    const startListening = () => {
      if (tokens <= 0) {
        alert(
          "Your token balance is zero.  Please purchase tokens.  Then, try again."
        );
        return;
      } else {
        if (shouldBlockAnswer()) {
          const button = document.querySelector(".VB-StartListeningButton");
          if (button) {
            button.style.backgroundColor = "rgb(219, 45, 45)"; // Change color to red
            button.textContent = "Not Yet...";
            // Set a timeout to revert the color back after 250ms
            setTimeout(() => {
              button.style.backgroundColor = ""; // Revert to the initial color
              button.textContent = "Respond";
            }, 1500);
          }
  
          return;
        }
        if (isListeningRef.current) {
          return;
        }
  
        recognition.onstart = () => {
          addInteraction('Speech recognition listening.');
          console.log("Speech recognition is listening.");
          isListeningRef.current = true;
          setVoiceBotState((prevState) => ({ ...prevState, isListening: true }));
          updateListeningButtonState(true);
          startTimer();
        };
  
        recognition.onend = () => {
          addInteraction('Speech recognition has stopped listening.');
          console.log("Speech recognition has stopped listening.");
          isListeningRef.current = false;
          setVoiceBotState((prevState) => ({ ...prevState, isListening: false }));
          updateListeningButtonState(false);
        };
  
        recognition.onerror = (event) => {
          console.error("Speech recognition error", event.error);
        };
  
        recognition.onresult = (event) => {
          const current = event.resultIndex;
          const transcript = event.results[current][0].transcript;
          const transcriptLength = transcript.trim().length;
  
          setSpokenChars((prevChars) => prevChars + transcriptLength);
  
          if (userSpeechRef.current) {
            userSpeechRef.current.value +=
              (userSpeechRef.current.value ? " " : "") + transcript;
          }
        };
  
        recognition.lang = "en-US";
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;
        recognition.start();
      }
    };


    // Handler for user typing in the textarea
  const handleUserTyping = (e) => {
    startTimer();
    const typedLength = e.target.value.length;
    const newTypedChars = typedLength - prevLength;
    if (newTypedChars > 0) {
      // Only update for typing, not deletion
      setTypedChars((prevTypedChars) => prevTypedChars + newTypedChars);
    }
    setPrevLength(typedLength); // Update prevLength for the next change
    console.log(
      "typedLength: ",
      typedLength,
      " newTypedChars: ",
      newTypedChars,
      " total typedChars: ",
      typedChars + newTypedChars
    );
  };

  const clearTextArea = () => {
    if (userSpeechRef.current) {
      userSpeechRef.current.value = "";
    }
  };

  const stopListening = () => {
    recognition.stop();
    isListeningRef.current = false;
    setVoiceBotState((prevState) => ({ ...prevState, isListening: false }));
    updateListeningButtonState(false);
  };

  const updateListeningButtonState = (isListening) => {
    const button = document.querySelector(".VB-StartListeningButton");
    if (button) {
      if (isListening) {
        button.style.backgroundColor = "green";
        button.textContent = "Listening...";
      } else {
        button.style.backgroundColor = "";
        button.textContent = "Respond";
      }
    } else {
      console.error("Start Listening button not found");
    }
  };

  const shouldBlockAnswer = () => {
    return false;
  };

  const handleSubmit = () => {
    
    stopListening();
    
  };

  const options = boxTitle === "Vocabulary"
  ? ["A1", "A2", "B1", "B2", "C1", "C2", "M1", "M2"]
  : ["A1", "A2", "B1", "B2", "C1", "C2"];
 
  const handleTrialSelect = (trials) => {
    setNumTrials(trials);
  };
  
  return (
    <div className="VB-container">
      <h1 className="VB-header-title">The iSpeakWell Project</h1>
      <div className="main-container">
        <div className="video-input-container">
          <div className="video-container">
            <YouTube
              videoId={videoId}
              opts={opts}
              onReady={handlePlayerReady}
              onStateChange={handlePlayerStateChange}
            />
            <div className="input-container">
            <input
              type="text"
              value={videoId}
              onChange={handleInputChange}
              placeholder="Enter YouTube video ID"
            />
          <button className="VidPlayButton" onClick={handlePlayClick}>Get</button>
        </div>
            <div className="playlist-container">
              <h3 className="playlist-title" onClick={togglePlaylistVisibility} style={{ cursor: 'pointer', color: 'grey' }}>
                Sources
              </h3>
              <div className={`playlist-list ${isPlaylistVisible ? 'fade-in' : 'fade-out'}`}>
                {isPlaylistVisible && (
                  <ul>
                    {playlistVideos.length > 0 ? (
                      playlistVideos.map(video => (
                        video.snippet ? (
                          <li 
                            key={video.id} 
                            onClick={() => handleVideoClick(video.snippet.resourceId.videoId)}
                            style={{ cursor: 'pointer', color: 'grey' }}
                          >
                            {video.snippet.title}
                          </li>
                        ) : null
                      ))
                    ) : (
                      <p>No videos found in the playlist.</p>
                    )}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      
        <img className="Curtains"
        src={Curtains}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "93%", 
          height: "150%", 
          opacity: "0.40",
          objectFit: "cover",
          objectPosition: "top",
        }}
        
        />
        <img className="stagelights"
        src={StageLights}
        alt="Overlay"
        style={{
          position: "absolute",
          top: -120,
          left: -75,
          width: "110%", 
          height: "40%", 

        }}
        />

        <img className="comfychair"
        src={ComfyChair}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 475,
          left: 105,
          width: "40%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="plant1"
        src={Plant1}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 400,
          left: 570,
          width: "25%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="bookshelf"
        src={Bookshelf1}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 200,
          left: 1100,
          width: "50%", 
          height: "80%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="seat"
        src={SeattoRideImage}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 450,
          left: 500,
          width: "65%", 
          height: "50%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
        />
        <img className="studiomonitor"
        src={StudioMonitor}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 300,
          left: -100,
          width: "18%", 
          height: "100%", 
        }}
        />
        
        <img className="studiomic"
        src={StudioMic}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 0,
          left: 1115,
          width: "40%", 
          height: "100%", 
        }}
        />
        <div className="VB-VoiceBot-container">
          <VoiceBotIframe />         
        </div>
      </div>
      
      <div className="button-grid">
        {["Vocabulary", "Grammar", "Talk", "Idiom", "Persuasion", "Culture", "Text", "Progress", "Fluency", "Logic"].map((title) => (
          <button key={title} className="square-button" onClick={() => handleButtonClick(title)}>{title}</button>
        ))}
      </div>
      <div className="button-grid2">
        {["Begin", "End"].map((title) => (
          <button key={title} className="square-button2" onClick={() => handleButtonClick(title)}>{title}</button>
        ))}
      </div>
      <div
          className={`info-box ${boxVisible ? 'fade-in' : 'fade-out'}`}
          onClick={handlePlayClick}
          style={{
            position: 'fixed',
            bottom: 10,
            left: 10,
            width: '35%',
            height: '70%',
            backgroundColor: 'rgba(50, 50, 50, 0.8)',
            padding: '20px',
            opacity: boxVisible ? 1 : 0,
            transition: 'opacity 0.5s ease, transform 0.5s ease',
            transform: boxVisible ? 'translateY(0)' : 'translateY(20px)',
            borderTopRightRadius: '15px',
          }}
        >
        <h2 style={{ textAlign: 'left', color: 'white' }}>{boxTitle}</h2>
        {boxTitle === "Transcript" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            {content}  {/* Only render content here, without fallback */}
            <Text />
          </div>
        )}
        {boxTitle === "Vocabulary" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <div className="tabs">
              <button onClick={() => handleTabChange('vocabulary')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Levelled Vocabulary
              </button>
              <button onClick={() => handleTabChange('wordCloud')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Source Word Cloud
              </button>
              <button onClick={() => handleTabChange('lexicon')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Source Lexicon
              </button>
            </div>

            {activeTab === 'vocabulary' && (
              <div>
                <h3 style={{ textAlign: 'left', color: 'white' }}>Levelled Vocabulary</h3>
                <Vocabulary vocabulary={vocabulary} level={userLevel} />
              </div>
            )}

            {activeTab === 'wordCloud' && (
              <div>
                <h3 style={{ textAlign: 'left', color: 'white' }}>Source Word Cloud</h3>
                <div className="WordCloudDimensioner" style={{ width: '100%', margin: '20px 0', zIndex: '50' }}>
                  <h5 style={{ textAlign: 'left', color: 'white' }}>Width</h5>
                  <input type="range" min="2" max="100" value={wordCloudWidth} onChange={handlewordCloudWidthSliderChange} />
                  <h6>{wordCloudWidth}</h6>
                  <h5 style={{ textAlign: 'left', color: 'white' }}>Height</h5>
                  <input type="range" min="2" max="1000" value={wordCloudHeight} onChange={handlewordCloudHeightSliderChange} />
                  <h6>{wordCloudHeight}</h6>
                </div>
                <WordCloud
                  words={wordCloudData}
                  options={{
                    colors: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", '#FFB6C1', '#E0FFFF', '#ADD8E6', '#F0E68C', '#FFD700', '#FF6347', '#98FB98', '#FF1493', '#B0E0E6', '#F5DEB3', '#D8BFD8', '#FFFACD', '#98C9E7', '#F8BBD0', '#FA8072', '#E6E6FA', '#B0E57C', '#F0F8FF'],
                    enableTooltip: false,
                    deterministic: false,
                    fontFamily: "impact",
                    fontSizes: [25, 60],
                    fontStyle: "normal",
                    fontWeight: "normal",
                    padding: 1,
                    rotations: 3,
                    rotationAngles: [0, 90],
                    scale: "sqrt",
                    spiral: "archimedean",
                    transitionDuration: 500,
                  }}
                />
              </div>
            )}

            {activeTab === 'lexicon' && (
              <div>
                <h3 style={{ textAlign: 'left', color: 'white' }}>Source Lexicon</h3>
                <ol style={{ color: 'white', margin: "5px", padding: "0px 40px 10px 40px" }}>
                  {Object.entries(memoizedVocabulary).map(([token, timestamps], index) => (
                    <li key={index} style={{ margin: "10px 0" }}>
                      <button
                        onClick={() => handleVocabularyClick(token)}
                        style={{ color: 'white', margin: "5px", padding: "10px", cursor: "pointer" }}
                      >
                        {token}
                      </button>
                      {loadingWord === token && (
                        <div style={{
                          position: "absolute",
                          top: "9.7%",
                          left: "540px",
                          transform: "translateY(-70%)",
                          zIndex: 10
                        }}>
                          <Spinner />
                        </div>
                      )}
                      {definitions[token] && (
                        <div style={{ marginTop: '5px', color: 'white', fontSize: '14px' }}>
                          <strong>Available Definition(s):</strong>
                          <ol>
                            {definitions[token].map((defObj, i) => (
                              <li key={i} style={{ color: 'white', marginBottom: '5px' }}>
                                {defObj.definition}
                              </li>
                            ))}
                          </ol>
                          {vocabulary[token] && vocabulary[token].length > 0 && (
                            <div style={{ marginTop: '10px' }}>
                              <strong>Jump to Timestamp(s):</strong>
                              <ol>
                                {vocabulary[token].map((timestamp, i) => (
                                  <li key={i} style={{ color: 'lightblue', cursor: 'pointer' }} onClick={() => handleTimestampClick(timestamp)}>
                                    [{formatTimestamp(timestamp)}]
                                  </li>
                                ))}
                              </ol>
                            </div>
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        )}
        {boxTitle === "Grammar" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            
            < Grammar
                level={userLevel}/>
          </div>
        )}
        {boxTitle === "Culture" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <div className="tabs">
              <button onClick={() => handleCultureTabChange('Search')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Train
              </button>
              <button onClick={() => handleCultureTabChange('Topics')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Topics
              </button>
            </div>
            {activeCultureTab === 'Search' && (
                <GoogleSearch />
            )}
            {activeCultureTab === 'Topics' && (
              < Culture
                level={userLevel}/>
            )}
          </div>
        )}
        {boxTitle === "Idiom" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            
            < Idiom
                level={userLevel}/>
          </div>
        )}
        {boxTitle === "Fluency" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <div className="tabs">
              <button onClick={() => handleFluencyTabChange('FM')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Fluency Metre
              </button>
              <button onClick={() => handleFluencyTabChange('Elements')} style={{ margin: '0 10px', cursor: 'pointer' }}>
                Elements
              </button>
            </div>
            {activeFluencyTab === 'FM' && (
              <div style={{
                  overflow: "hidden",
                  height: "420px",
                }}>
                  <VoiceBotToolsIframe />
                  <div className="FluencyTrainer">
                    <div className="vidControls">
                      <button onClick={handlePlay}>Play</button>
                      <button onClick={handlePause}>Pause</button>
                      <button onClick={handleStop}>Stop</button>
                      <button onClick={handleRepeat500ms}>Repeat 500ms</button>
                      <button onClick={handleRepeatCycle}>Repeat Trial Cycle</button>
                      <button onClick={handleRepeat2s}>Repeat 2s</button>
                    </div>
                    <div className="TrialSelect">
                      <button onClick={() => handleTrialSelect(2)}>2 Trials</button>
                      <button onClick={() => handleTrialSelect(3)}>3 Trials</button>
                      <button onClick={() => handleTrialSelect(4)}>4 Trials</button>
                      <button onClick={() => handleTrialSelect(5)}>5 Trials</button>
                      <button onClick={() => handleTrialSelect(10)}>10 Trials</button>
                    </div>
                    <div>{numTrials} trials selected</div>
                  </div>
              </div>
            )}
            {activeFluencyTab === 'Elements' && (
                < Fluency
                level={userLevel}/>
              )}
            
          </div>
        )}
        {boxTitle === "Persuasion" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            
            < Persuasion
                level={userLevel}/>
          </div>
        )}
        {boxTitle === "Logic" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            
            < Logic
                level={userLevel}/>
          </div>
        )}
        
        {boxTitle === "Talk" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <div className="tabs">
              <button onClick={() => handleTalkTabChange('AI')} style={{ margin: '0 10px', cursor: 'pointer' }}>
              Train
              </button>
              <button onClick={() => handleTalkTabChange('Topics')} style={{ margin: '0 10px', cursor: 'pointer' }}>
              Topics
              </button>
              <button onClick={() => handleTalkTabChange('Chat')} style={{ margin: '0 10px', cursor: 'pointer' }}>
              Chat
              </button>
            </div>
              {activeTalkTab === 'AI' && (
                <div style={{
                  overflow: "hidden",
                  margin: "50px 0px",
                }}>
                <div className="VB-UserControls">
                  <textarea
                    ref={userSpeechRef}
                    id="speech"
                    className="VB-userSpeech"
                    onChange={handleUserTyping}
                    placeholder="Position your microphone close to your mouth but away from your speech stream. Type here or click the `Respond` button, below, to use speech recognition when you are ready to speak."
                    disabled={shouldBlockAnswer()}
                  ></textarea>
                  <h4 className="VB-instruct1">Wait for a moment of silence before responding.</h4>

                  <div className="VB-SpeechRecButtons">
                      <button
                        className="VB-StartListeningButton"
                        onClick={startListening}
                        disabled={voiceBotState.isListening}
                      >
                        Respond
                      </button>
                      <button
                        className="VB-StpBttn"
                        onClick={stopListening}
                        disabled={!voiceBotState.isListening}
                      >
                        Stop
                      </button>
                      <button
                        className="VB-submitAnswer"
                        onClick={handleSubmit}
                        disabled={shouldBlockAnswer()}
                      >
                        Add
                      </button>
                      <button className="VB-clearButton" onClick={clearTextArea}>
                        Clear
                      </button>
                    </div>
                  
                  </div>
                </div>
              )}
              {activeTalkTab === 'Topics' && (
                <Talk
                level={userLevel}/>
              )}
              {activeTalkTab === 'Chat' && (
                <div
                  style={{
                    width: '100%',
                    height: '40vh',
                    overflow: 'hidden',
                    position: 'relative',
                    margin: '50px 0px',
                  }}
                >
                  {!login ? (
                    // Display the login form if not logged in
                    <div
                      style={{
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                      }}
                    >
                      <h2>Login to Chat</h2>
                      <form onSubmit={handleLoginSubmit} style={{ textAlign: 'center' }}>
                        <div>
                          <label>
                            Username:
                            <input
                              type="text"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                              style={{ padding: '8px', margin: '10px 0' }}
                            />
                          </label>
                        </div>
                        <div>
                          <label>
                            Room:
                            <select
                              value={room}
                              onChange={(e) => setRoom(e.target.value)}
                              required
                              style={{ padding: '8px', margin: '10px 0' }}
                            >
                              {rooms.map((roomOption) => (
                                <option key={roomOption} value={roomOption}>
                                  {roomOption}
                                </option>
                              ))}
                            </select>
                          </label>
                        </div>
                        <button
                          type="submit"
                          style={{
                            padding: '10px 20px',
                            backgroundColor: '#4CAF50',
                            color: 'white',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                          }}
                        >
                          Log In
                        </button>
                      </form>
                    </div>
                  ) : (
                    // Display iframe after login
                    <iframe
                      id = "theChat"
                      src={iframeSrc} // Assuming iframeSrc is defined elsewhere
                      title="Chat Application"
                      style={{
                        border: 'none',
                        width: '100%',
                        height: '100%',
                        display: 'block',
                        objectFit: 'contain',
                        opacity: '100',
                      }}
                      key={activeTalkTab}
                    />
                  )}
                  {login && (
                          <button
                            id="leave"
                            onClick={leaveRoom}
                            style={{
                              position: 'absolute',
                              zIndex: '100',
                              top: '14px',
                              left: '20px',
                              cursor: 'pointer',
                              color: 'white',
                            }}
                          >
                            Leave
                          </button>
                        )}
                </div>
              )}
          </div>
        )}
        {boxTitle === "Begin" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            < Begin
                level={userLevel}/>
          </div>
        )}
        {boxTitle === "End" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Expanders</h3>
            <h4 style={{ textAlign: 'left', color: 'white' }}>- In Progress</h4>
            <h3 style={{ textAlign: 'left', color: 'white' }}>Timestamped Statements</h3>
            < End
                level={userLevel}/>
          </div>
        )}
        {boxTitle === "Progress" && (
          <div style={{ color: 'white', overflowX: 'hidden', overflowY: 'auto', maxHeight: '80%' }}>
            < Progress
                level={userLevel}
              />
          </div>
        )}

        <select 
              className="LearnerLevel" 
              value={userLevel} 
              onChange={handleLevelChange} 
              style={{ width: '35%', margin: '22px 0', zIndex: '50' }}
        >
              <option value="">--Select Level--</option>
              {options.map(option => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
        </select>
        <button onClick={handleHideClick} style={{
          position: 'absolute',
          bottom: '10px',
          right: '20px',
          cursor: 'pointer',
          color: 'white',
        }}>
          X
        </button>
      </div>

      <img className="monitor"
        src={BackofMonitorImage}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 675,
          left: 590,
          width: "55%", 
          height: "66%", 
          objectFit: "cover",
          objectPosition: "top",
        }}
      />
      <img className="plant2"
        src={Plant2}
        alt="Overlay"
        style={{
          position: "absolute",
          top: 425,
          left: 1400,
          width: "25%", 
          height: "78%", 
          objectFit: "cover",
          objectPosition: "top", 

        }}
        />
      
      <nav className="logout-nav">
        {/* Hamburger Menu Icon */}
        <IconButton className="IP-menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon
            style={{
              boxShadow: "0 0 50px #000000, 0 0 20px #ffffff",
              // Add additional styles if needed
            }}
          />
        </IconButton>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer(false)}
          className="custom-drawer"
        >
          {list()}
        </Drawer>
      </nav>
      <div className = "OverLay">
        <Overlay
          username={username}
          visits={visits}
          totalTimeOnSite={totalTimeOnSite}
          phonemesPerMinute={phonemesPerMinute}
          wordsPerMinute={wordsPerMinute}
          totalWordsAcquired={totalWordsAcquired}
          newWordsAcquired={newWordsAcquired}
          twoPhonemeCombinations={twoPhonemeCombinations}
          vocab={vocab}
          grammar={grammar}
          idiom={idiom}
          persuasion={persuasion}
          logic={logic}
          culture={culture}
          fluency={fluency}
          talk={talk}
          progress={progress}
          testsCompleted={testsCompleted}
          aggregatedScore={aggregatedScore}
          lastInteraction={lastInteraction}
        />
      </div>
    </div>
  );
}
export default VB;