import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Persuasion.css";

function Persuasion({ level }) {
  const [persuasionData, setPersuasionData] = useState([]);
  const [filteredPersuasion, setFilteredPersuasion] = useState([]);

  // Fetch and parse the conversation Persuasion data
  useEffect(() => {
    Papa.parse("/data/Persuasion.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setPersuasionData(transformedData); // Store all conversation Persuasion data
        
      },
    });
  }, []);

  // Filter Persuasion based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredPersuasion(
        persuasionData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredPersuasion([]); // Clear the list if no level is selected
    }
  }, [level, persuasionData]);

  return (
    <div>
      {filteredPersuasion.length > 0 ? (
        filteredPersuasion.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Persuasion;
