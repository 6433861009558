import React from "react";
import "./Text.css";

function Text() {
  
  return (
    <div className="">
      <p>Boo!</p>
    </div>
  );
}

export default Text;