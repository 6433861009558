import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Idiom.css";

function Idiom({ level }) {
  const [idiomData, setidiomData] = useState([]);
  const [filteredIdiom, setFilteredIdiom] = useState([]);

  // Fetch and parse the conversation Idiom data
  useEffect(() => {
    Papa.parse("/data/Idiom.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setidiomData(transformedData); // Store all conversation Idiom data
        
      },
    });
  }, []);

  // Filter Idiom based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredIdiom(
        idiomData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredIdiom([]); // Clear the list if no level is selected
    }
  }, [level, idiomData]);

  return (
    <div>
      {filteredIdiom.length > 0 ? (
        filteredIdiom.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No items found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Idiom;
