import React, { useState, useEffect } from 'react';

const Timer = ({ onTimeUpdate }) => {
  const [time, setTime] = useState(0); // Time in seconds

  // This effect will run once the `time` state changes.
  useEffect(() => {
    if (onTimeUpdate) {
      onTimeUpdate(time); // Pass the updated time to the parent component
    }
  }, [time, onTimeUpdate]); // This effect runs when `time` changes

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime + 1); // Increment the time every second
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []); // Empty dependency array to start the interval once on mount

  return (
    <div>
      <h3>{formatTime(time)}</h3>
    </div>
  );
};

// Format time as MM:SS
const formatTime = (seconds) => {
  const hours = Math.floor(seconds / 3600); // 1 hour = 3600 seconds
  const minutes = Math.floor((seconds % 3600) / 60); // Remaining minutes
  const sec = seconds % 60; // Remaining seconds
  if (hours === 0){
    return `${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
  } else{
  // Return in HH:MM:SS format, ensuring leading zeroes where necessary
   return `${String(hours)}:${String(minutes).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
  }
};


export default Timer;
