import "./Conversationpractice.css";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../utils/firebase";
import VoiceBotIframe from "./VoiceBotiFrame";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { getAuth } from "firebase/auth";
import { db } from "../utils/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  serverTimestamp,
  arrayUnion,
} from "firebase/firestore";
import Spinner from "./Spinner";
import axios from "axios";
import textToSpeech from "./TextToSpeech";
import ConversationTopicsandGoals from "./ConversationTopicsandGoals";
import serverLog from "./Logger";

function ConversationPractice() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = React.useState(false);
  const [tokens, setTokens] = useState(0);
  const userSpeechRef = useRef(null);
  const [chatMessages, setChatMessages] = useState([]);
  const isWelcomeMessageAdded = useRef(false);
  const [isListening, setIsListening] = useState(false);
  const [transcribedText, setTranscribedText] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [coachMessages, setCoachMessages] = useState([]);
  const isCoachMessageAdded = useRef(false);
  const [showChatBoxes, setShowChatBoxes] = useState(false);
  const [text, setText] = useState("");
  const messagesEndRef = useRef(null);
  const coachMessagesEndRef = useRef(null);
  const [voice, setVoice] = useState("en-US-Wavenet-I"); // Default voice selection
  const [partner, setPartner] = useState(10000);
  const [showPartnerSelector, setShowPartnerSelector] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [hasPaid, setHasPaid] = useState(false);
  const [numMoves, setNumMoves] = useState(0);
  const [showTopicsAndGoals, setShowTopicsAndGoals] = useState(true);
  const [desiredRole, setDesiredRole] = useState("");
  const [selectedGoal, setSelectedGoal] = useState("");
  const [selectedMileStones, setSelectedMileStones] = useState("");
  const [desiredRoleSet, setDesiredRoleSet] = useState(false);
  const [conversationId, setConversationID] = useState("");

  useEffect(() => {
    const fetchTokens = async () => {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);
        try {
          const userDoc = await getDoc(userRef);
          if (userDoc.exists()) {
            setTokens(userDoc.data().tokens || 0);
          }
        } catch (error) {
          console.error("Error fetching token count: ", error);
        }
      }
    };

    fetchTokens();
  }, []);

  useEffect(() => {
    if (desiredRole !== "" && desiredRole.length >= 3 && selectedGoal !== "") {
      setDesiredRoleSet(true);
    } else {
      setDesiredRoleSet(false);
    }
    console.log(desiredRole, selectedGoal, selectedMileStones);
  }, [desiredRole, selectedGoal, selectedMileStones]);

  const handleDesiredRoleChange = (newRole) => {
    setDesiredRole(newRole);
  };

  const handleSelectedGoalChange = (newGoal) => {
    setSelectedGoal(newGoal);
  };

  const handleSelectedMilestones = (newMileStones) => {
    setSelectedMileStones(newMileStones);
  };

  const deductToken = async () => {
    const newTokenCount = tokens - 1;
    setTokens(newTokenCount); // Update the local state

    // Update the database
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const userRef = doc(db, "users", user.uid);
        await updateDoc(userRef, { tokens: newTokenCount });
      }
    } catch (error) {
      console.error("Error updating token count: ", error);
      // Optionally, handle any errors, like reverting the local state change
    }
  };

  // Function to add a new message to the chat
  useEffect(() => {
    if (!isWelcomeMessageAdded.current) {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          author: "Instructor",
          text: "Welcome to conversation practice! Select a partner.  Then, let them know when you're ready to talk.",
        },
      ]);
      isWelcomeMessageAdded.current = true;
    }

    if (!isCoachMessageAdded.current) {
      setCoachMessages((prevMessages) => [
        ...prevMessages,
        {
          author: "Coach",
          text: "Conversation hints are posted here.  Start your conversation with a greeting to your partner.",
        },
      ]);
      isCoachMessageAdded.current = true;
    }
  }, [isWelcomeMessageAdded, isCoachMessageAdded]);

  const handleTextToSpeech = useCallback(async () => {
    setShowPartnerSelector(false);
    const maxRetries = 3;
    let attempts = 0;
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    const sendRequest = async () => {
      try {
        serverLog(userId + ' Text to Speech called.');
        const audioContent = await textToSpeech(text, voice);
        serverLog(userId + ' Speech returned.');
        const apiUrl = 'https://audlister.ispeakwell.ca/convertToMP3';
        serverLog(userId + ' Speech sent to save.');
        const response = await axios.post(apiUrl, { audioContent });
        const mp3FileUrl = response.data.fileName;
        serverLog(userId + ' Speech saved: ' + response);
        console.log("here is the response: ", response);
        serverLog(userId + ' Saved File URL: ' + mp3FileUrl);
        console.log("MP3 File URL: ", mp3FileUrl);
        const iframeWindow = document.getElementById("theBot").contentWindow;
        iframeWindow.postMessage(
          { type: "text", url: mp3FileUrl },
          "https://voicebot.ispeakwell.ca/"
        );
      } catch (error) {
        console.log(text, voice);
        serverLog(userId + ' Error: ' + text + voice);
        if (attempts < maxRetries) {
            attempts++;
            console.log(`Retry attempt ${attempts}: `, error);
            setTimeout(sendRequest, 1000 * attempts); // Linear backoff: wait longer with each attempt
        } else {
            console.error("Max retries reached. Error:", error);
            serverLog(userId + ' Speech save failed.');
        }
      }
    };
    sendRequest();

  }, [text, voice]);

  const openChatBoxes = () => {
    setShowChatBoxes(!showChatBoxes);
    if (!showChatBoxes) {
      // Get a reference to the iframe element
      setShowTopicsAndGoals(false);
      var iframeWindow = document.getElementById("theBot").contentWindow;

      if (iframeWindow) {
        iframeWindow.postMessage(partner, "https://voicebot.ispeakwell.ca/");
      }
    }
  };

  useEffect(() => {
    const iframeWindow = document.getElementById("theBot").contentWindow;
    if (iframeWindow) {
      iframeWindow.postMessage(partner, "https://voicebot.ispeakwell.ca/");
    }
  }, [partner]);

  const changePartner = (changeToPartner) => {
    setPartner(changeToPartner);
  };

  useEffect(() => {
    const voiceMapping = {
      10000: "en-US-Wavenet-I", // Example voice name for changeToPartner value 10000
      10001: "en-US-Wavenet-G", // Example voice name for changeToPartner value 10001
      10002: "en-US-Wavenet-F", // Example voice name for changeToPartner value 10002
      10003: "en-US-Wavenet-J", // Example voice name for changeToPartner value 10003
    };
    setVoice(voiceMapping[partner]);
    console.log("Voice: ", voice);
  }, [partner, voice]);

  const formatTime = (milliseconds) => {
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;

    // Formatting to ensure two digits
    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = seconds.toString().padStart(2, "0");

    if (hours > 0) {
      return `${formattedHours}h ${formattedMinutes}m ${formattedSeconds}s`;
    } else if (minutes > 0) {
      return `${formattedMinutes}m ${formattedSeconds}s`;
    } else {
      return `${formattedSeconds}s`;
    }
  };

  const speechRecognition =
    window.SpeechRecognition || window.webkitSpeechRecognition;
  let recognition = new speechRecognition();

  recognition.onstart = function () {
    console.log("Voice recognition started. Speak into the microphone.");
    setIsListening(true);
    startTimer();
    updateListeningButtonState(true);
  };

  recognition.onresult = function (event) {
    const current = event.resultIndex;
    const transcript = event.results[current][0].transcript;
    setTranscribedText(transcribedText + " " + transcript);
    console.log(transcript);
  };

  recognition.onend = function () {
    console.log("Voice recognition stopped.");
    setIsListening(false);
    updateListeningButtonState(false);
  };

  const InterViewPractice = () => {
    navigate("/interview-practice");
  };

  const iSpeakWell = () => {
    navigate("/voicebot");
  };

  const Dashboard = () => {
    navigate("/resumerevisor");
  };

  const Purchase = () => {
    navigate("/purchase");
  };

  const admin = () => {
    navigate("/admin");
  };

  const Logout = async () => {
    await logout();
    navigate("/login");
  };

  const TermsofService = () => {
    navigate("/termsofservice");
  };

  const Privacy = () => {
    navigate("/privacypolicy");
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(open);
  };

  const list = () => (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={Purchase}>
          <ListItemText primary={`Tokens: ${tokens}`} />
        </ListItemButton>
        <ListItemButton onClick={Dashboard}>
          <ListItemText primary="Resume Revisor" />
        </ListItemButton>
        <ListItemButton onClick={InterViewPractice}>
          <ListItemText primary="Interview Practice" />
        </ListItemButton>
        <ListItemButton onClick={iSpeakWell}>
          <ListItemText primary="iSpeakWell" />
        </ListItemButton>
        <ListItemButton onClick={admin}>
          <ListItemText primary="Admin" />
        </ListItemButton>
        <ListItemButton onClick={Logout}>
          <ListItemText primary="Logout" />
        </ListItemButton>
        <ListItemButton onClick={TermsofService}>
          <ListItemText primary="Terms of Service" />
        </ListItemButton>
        <ListItemButton onClick={Privacy}>
          <ListItemText primary="Privacy" />
        </ListItemButton>
      </List>
    </div>
  );

  const updateListeningButtonState = (isListening) => {
    const button = document.querySelector(".CP-StartListeningButton");
    if (button) {
      if (isListening) {
        button.style.backgroundColor = "green";
        button.textContent = "Listening...";
      } else {
        button.style.backgroundColor = "";
        button.textContent = "Speak";
      }
    } else {
      console.error("Start Listening button not found");
    }
  };

  const shouldBlockAnswer = () => {
    if (!showChatBoxes) {
      return true;
    } else {
      return false;
    }
  };

  const startTimer = () => {
    if (startTime === null) {
      setStartTime(new Date());
    }
  };

  // When submitting an answer
  const stopTimer = () => {
    const currentEndTime = new Date();
    const timeTaken = currentEndTime - startTime; // Calculate time taken
    // If you need to update the state or use this value in your component, do it here
    console.log("Time taken:", formatTime(timeTaken > 0 ? timeTaken : 0));
    setStartTime(null);
    return timeTaken > 0 ? timeTaken : 0; // Ensure non-negative value
  };

  const startListening = () => {
    if (tokens <= 0) {
      alert(
        "Your token balance is zero.  Please purchase tokens.  Then, try again."
      );
      return;
    } else {
      setIsListening(true);
      recognition.start();
    }
  };

  const stopListening = () => {
    recognition.stop();
    stopTimer();
    updateListeningButtonState(false);
  };

  useEffect(() => {
    if (text) {
      handleTextToSpeech();
    }
    console.log(text);
  }, [text, handleTextToSpeech]); // Dependency array includes audioUrl

  useEffect(() => {
    setTimeout(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 0);
  }, [chatMessages, processing]);

  useEffect(() => {
    setTimeout(() => {
      coachMessagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 250);
  }, [coachMessages, processing]);

  const startConversation = async (userMessage, aiResponse, userId) => {
    const collectionRef = collection(db, "conversations");
    try {
      const docRef = await addDoc(collectionRef, {
        userId: userId,
        desiredRole: desiredRole,
        selectedGoal: selectedGoal,
        messages: [userMessage, aiResponse],
        selectedMileStones: selectedMileStones,
        timestamp: serverTimestamp(),
      });
      console.log("Conversation started with ID: ", docRef.id);
      setConversationID(docRef.id); // Store the conversation ID in state
      return docRef.id; // Return the ID of the newly created document
    } catch (error) {
      console.error("Error starting conversation: ", error);
    }
  };

  const updateConversation = async (conversationId, newMessage) => {
    const conversationRef = doc(db, "conversations", conversationId);
    await updateDoc(conversationRef, {
      messages: arrayUnion(...newMessage),
      lastUpdated: serverTimestamp(),
    });
  };

  const handleSubmit = async () => {
    if (tokens<=0){
      alert("You will need at least 1 token to practice conversation with AI. Please purchase a token.");
      return;
    }
    const auth = getAuth();
    const user = auth.currentUser;
    const userId = user ? user.uid : null;

    if (!userId) {
      console.error("No user logged in");
      return; // Exit the function if there's no user logged in
    }

    if (numMoves < 100) {
      if (!hasPaid) {
        const confirmPayment = window.confirm(
          "Starting a conversation will require 1 Token. Do not leave this page during the conversation."
        );
        if (confirmPayment) {
          // Process the payment
          deductToken();
          setHasPaid(true);
        } else {
          // User refused to pay, exit the function
          return;
        }
      }

      // Process the user's submission, this part runs after payment is made, or if the user has already paid
      setProcessing(true);
      if (transcribedText.trim()) {
        // First, update the local state with the user's message
        setChatMessages((prevMessages) => [
          ...prevMessages,
          { author: "user", text: transcribedText },
        ]);

        // Prepare the messages to be sent, now including the new user message
        const messagesToSend = chatMessages.map((msg) => ({
          role: msg.author === "user" ? "user" : "assistant",
          content: msg.text,
        }));

        // If it's the user's message, append the additional information to the content
        if (transcribedText) {
          let userMessageContent = transcribedText;

          // Optionally, append additional information to the user's message
          if (desiredRole) {
            userMessageContent += `\n[Desired Role: ${desiredRole}]`;
          }
          if (selectedGoal) {
            userMessageContent += `\n[Selected Goal: ${selectedGoal}]`;
          }
          if (selectedMileStones && selectedMileStones.length > 0) {
            const milestonesString = selectedMileStones.join(", ");
            userMessageContent += `\n[Selected Milestones: ${milestonesString}]`;
          }

          // Add the user's message with the additional content to the messages array
          messagesToSend.push({ role: "user", content: userMessageContent });
        }

        console.log("Message to OpenAI: ", messagesToSend);

        setTranscribedText("");
        try {
          const response = await axios.post(
            "https://fluency.ispeakwell.ca/api/conversation-practice",
            { messages: messagesToSend }
          );

          if (response.data && response.data.message) {
            const aiResponse = response.data.message;
            const userMessage = { author: "user", text: transcribedText };

            if (numMoves === 0) {
              // Pass the user ID to startConversation
              const newConversationId = await startConversation(
                userMessage,
                aiResponse,
                userId
              );
              setConversationID(newConversationId); // Set the conversation ID in state
            } else {
              if (conversationId) {
                updateConversation(conversationId, [userMessage, aiResponse]); // Use the conversation ID for updates
              } else {
                console.error("No conversation ID available for updating");
              }
            }
            // Splitting the AI response into partner and coach responses
            const responseParts = aiResponse.split("\n\n");
            const partnerResponse = responseParts[0];
            const coachResponse = responseParts[1];

            if (partnerResponse.startsWith("Partner: ")) {
              const newText = partnerResponse.replace("Partner: ", "");
              setText(newText);
            }

            setChatMessages((prevMessages) => [
              ...prevMessages,
              ...(partnerResponse && partnerResponse.includes("Partner:")
                ? [
                    {
                      author: "Partner",
                      text: partnerResponse.replace("Partner: ", ""),
                    },
                  ]
                : []),
            ]);

            setCoachMessages((prevMessages) => [
              ...prevMessages,
              ...(coachResponse && coachResponse.includes("Coach:")
                ? [
                    {
                      author: "Coach",
                      text: coachResponse.replace("Coach: ", ""),
                    },
                  ]
                : []),
            ]);
            setNumMoves(numMoves + 1);
          } else {
            console.error("Unexpected response structure:", response.data);
          }
        } catch (error) {
          console.error("Error communicating with OpenAI:", error);
        }
      } else {
        console.log("No text to send");
      }
      console.log("Conversational Move: ", numMoves + 1);
      setProcessing(false);
    } else {
      alert(
        "Your conversation has reached it's maximum length.  Try to complete your conversation before this point.  Please refresh your page and try again."
      );
    }
  };

  const clearTextArea = () => {
    if (userSpeechRef.current) {
      userSpeechRef.current.value = "";
      setTranscribedText("");
    }
  };

  return (
    <div className="CP-Conversation-Practice-container">
      <header>
        <h1 className="CP-Main-Header-convo">iSpeakWell</h1>
        <h2 className="CP-Main-Header-Tagline">Conversation Practice</h2>
        <h2 className="CP-Main-Header-Tagline">with AI</h2>
      </header>
      <div >
          <VoiceBotIframe />
      </div>
      <div
        className={`CP-CoreCasing ${showChatBoxes ? "show-chat-boxes" : ""}`}
      >
        <div
          className={`CP-VB-Chat-Session ${
            showChatBoxes ? "show-chat-boxes" : ""
          }`}
        >
          <div className="CP-SelectorButton">
            {showChatBoxes ? (
              <div
                className={`CP-UserConvoControls ${
                  showChatBoxes ? "visible" : ""
                }`}
              >
                {showPartnerSelector && (
                  <div className="CP-ConversationPartnersContainer">
                    <div className="CP-ConversationPartners">
                      <button onClick={() => changePartner("10000")}>
                        Cooper
                      </button>
                      <button onClick={() => changePartner("10001")}>
                        Vivian
                      </button>
                    </div>
                    <div className="CP-ConversationPartners">
                      <button onClick={() => changePartner("10002")}>
                        Malika
                      </button>
                      <button onClick={() => changePartner("10003")}>
                        Stephane
                      </button>
                    </div>
                  </div>
                )}
                {/* <div>{audioUrl && <audio src={audioUrl} controls />}</div> */}
                <textarea
                  id="speech"
                  ref={userSpeechRef}
                  className="CP-userConvoSpeech"
                  value={transcribedText}
                  onChange={(e) => setTranscribedText(e.target.value)}
                  placeholder="Position your microphone close to your mouth but away from your speech stream.

            Type here or click the `Respond` button, below, to use speech recognition when you receive a question to answer.
            
            Click `Stop` to stop speech recognition from working.
            
            Click `Clear` to clear out undesired text.
            
            Click `Add` to send spoken or typed text to OpenAI."
                  disabled={shouldBlockAnswer()}
                ></textarea>
                <div className="CP-ControlRecord">
                  <div className="CP-SpeechRecButtons">
                    <button
                      className="CP-StartListeningButton"
                      onClick={startListening}
                      disabled={isListening}
                    >
                      Speak
                    </button>
                    <button
                      className="CP-StpBttn"
                      onClick={stopListening}
                      disabled={!isListening}
                    >
                      Stop
                    </button>
                  </div>
                  <div className="CP-SpeechRecButtons">
                    <button className="CP-clearButton" onClick={clearTextArea}>
                      Clear
                    </button>
                    <button className="CP-submitAnswer" onClick={handleSubmit}>
                      Send
                    </button>
                  </div>
                </div>
                <h5 className="ConversationalMoves">
                  Conversational Moves Made: {numMoves} (max 20)
                </h5>
              </div>
            ) : (
              showTopicsAndGoals && (
                <div className="CP-OpenforBusiness">
                  <ConversationTopicsandGoals
                    onDesiredRoleChange={handleDesiredRoleChange}
                    onSelectedGoalChange={handleSelectedGoalChange}
                    onSelectedMilestonesChange={handleSelectedMilestones}
                  />
                </div>
              )
            )}
            {desiredRoleSet && showTopicsAndGoals ? (
              <button onClick={openChatBoxes} className="CP-Opener">
                {showChatBoxes ? "Leave" : "Let's Talk!"}
              </button>
            ) : showTopicsAndGoals ? (
              <div>
                <h5>Add a Desired Role and Select a Goal</h5>
              </div>
            ) : (
              <div className="CP-milestones-container">
                <h5 className="CP-milestones-Lesson">{selectedGoal}</h5>
                <ul className="CP-milestones-unorderedList">
                  {selectedMileStones.map((milestone, index) => (
                    <li key={index} className="CP-milestone-ListItem">
                      {milestone}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="CP-ChatBox-Container">
            <div
              className="CP-chat-box"
              style={{
                display: showChatBoxes ? "flex" : "none",
                transform: showChatBoxes
                  ? "translateX(0)"
                  : "translateX(-100%)",
                opacity: showChatBoxes ? 1 : 0,
                zIndex: showChatBoxes ? 1 : -1,
              }}
            >
              {chatMessages.map((msg, index) => (
                <div key={index} className={`CP-chat-message ${msg.author}`}>
                  <strong>{msg.author}</strong>: {msg.text}
                </div>
              ))}
              {processing ? (
                <div ref={messagesEndRef} style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <div ref={messagesEndRef}></div>
              )}
            </div>
            <div
              className="CP-coach-chat-box"
              style={{
                display: showChatBoxes ? "flex" : "none",
                transform: showChatBoxes
                  ? "translateX(0)"
                  : "translateX(-150%)",
                opacity: showChatBoxes ? 1 : 0,
                zIndex: showChatBoxes ? 1 : -1,
              }}
            >
              {coachMessages.map((msg, index) => (
                <div
                  key={index}
                  className={`CP-coach-chat-message ${msg.author}`}
                >
                  <strong>{msg.author}</strong>: {msg.text}
                </div>
              ))}
              {processing ? (
                <div ref={coachMessagesEndRef} style={{ textAlign: "center" }}>
                  <Spinner />
                </div>
              ) : (
                <div ref={coachMessagesEndRef}></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="CP-BottomLine"></div>
      <nav className="logout-nav">
        {/* Hamburger Menu Icon */}
        <IconButton className="menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon
            style={{
              boxShadow: "0 0 50px #000000, 0 0 20px #ffffff",
              // Add additional styles if needed
            }}
          />
        </IconButton>

        {/* Drawer for Mobile View */}
        <Drawer
          anchor="left"
          open={isOpen}
          onClose={toggleDrawer(false)}
          className="custom-drawer"
        >
          {list()}
        </Drawer>
      </nav>
    </div>
  );
}
export default ConversationPractice;
