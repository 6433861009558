import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Talk.css";

function Talk({ level }) {
  const [TalkersData, setTalkersData] = useState([]);
  const [filteredTalkers, setFilteredTalkers] = useState([]);

    
  // Fetch and parse the conversation Talkers data
  useEffect(() => {
    Papa.parse("/data/Talkers.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));
  
        setTalkersData(transformedData); // Store all conversation Talkers data
      },
    });
  }, []); // Empty dependency array ensures this runs only once on mount
  

  // Filter Talkers based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredTalkers(
        TalkersData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredTalkers([]); // Clear the list if no level is selected
    }
  }, [level, TalkersData]);

  return (
    <div>
      <h3 style={{ textAlign: 'left', color: 'white' }}> Discussion Topics</h3>
      {filteredTalkers.length > 0 ? (
          filteredTalkers.map((item, index) => (
            <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
          ))
        ) : (
          <p>{level ? "No conversation talkers found for this level." : "Select a level."}</p>
        )}
    </div>
  );
}

export default Talk;