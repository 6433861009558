import React from "react";
import { Triangle } from "react-loader-spinner";

const Spinner = () => (
  <div className="spinner-container">
    <Triangle
      visible={true}
      height="80"
      width="80"
      color="#007bff"
      ariaLabel="triangle-loading"
      wrapperStyle={{}}
      wrapperClass=""
    />
  </div>
);

export default Spinner;
