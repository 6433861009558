import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./Begin.css";

function Begin({ level }) {
  const [startersData, setStartersData] = useState([]);
  const [filteredStarters, setFilteredStarters] = useState([]);

  // Fetch and parse the conversation starters data
  useEffect(() => {
    Papa.parse("/data/Starters.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1); // Skip the header row
        const transformedData = data.map((row) => ({
          Level: row[0],
          Topic: row[2],
          Description: row[3],
        }));

        setStartersData(transformedData); // Store all conversation starters data
        
      },
    });
  }, [startersData]);

  // Filter starters based on the level prop
  useEffect(() => {
    if (level) {
      setFilteredStarters(
        startersData.filter((item) => item.Level === level)
      );
    } else {
      setFilteredStarters([]); // Clear the list if no level is selected
    }
  }, [level, startersData]);

  return (
    <div>
      {filteredStarters.length > 0 ? (
        filteredStarters.map((item, index) => (
          <div key={index}>
            <h4>{index + 1}. {item.Topic}</h4> {/* Adding the index + 1 for numbering */}
            <p style={{ marginLeft: '20px' }}>{item.Description}</p> {/* Indent with margin-left */}
          </div>
        ))
      ) : (
        <p>{level ? "No conversation starters found for this level." : "Select a level."}</p>
      )}
    </div>
  );
}

export default Begin;
