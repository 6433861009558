import React, { useEffect } from 'react';
import { useColorMode } from './ColorModeContext';
import { AuthProvider } from "./components/AuthContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import Dashboard from "./components/ResumeRevisor";
import About from "./components/About";
import Tokens from "./components/Tokens";
import Careers from "./components/Careers";
import Menu from "./components/Menu";
import Contactus from "./components/Contactus";
import ConversationPractice from "./components/Conversationpractice";
import ProtectedRoute from "./utils/ProtectedRoute";
import PasswordReset from "./components/PasswordReset";
import PaymentSuccessful from "./components/PaymentSuccessful";
import PageExpired from "./components/PageExpired";
import PaymentNotSuccessful from "./components/PaymentNotSuccessful";
import Purchase from "./components/Purchase";
import TermsofService from "./components/TermsofService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/NotFound";
import InterviewPractice from "./components/InterviewPractice";
import Admin from "./components/Admin";
import { VoiceBotStateProvider } from "./components/VoiceBotStateContext";
import { ColorModeProvider } from "./ColorModeContext";
import axios from "axios";
import { TokenProvider } from "./components/tokenContext";
import HexagonBackground from "./components/HexagonBackground";
import AccountDeleted from "./components/AccountDeleted";
import "./App.css";
import logosmall from "./components/logosmall.PNG";
import CookieConsent from "./components/CookieConsentComponent";
import CookiePolicy from "./components/CookiePolicy";
import SlideShow from "./components/SlideShow";
import VB from "./components/VB";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

function App() {  
  return (
    <AuthProvider>
      <ColorModeProvider>
        <ActualAppContent />
      </ColorModeProvider>
    </AuthProvider>
  );
}

const ActualAppContent = () => {
const { colorMode } = useColorMode();

  useEffect(() => {
    const root = document.documentElement; // Access the root element
    const newTextColor = colorMode <= 9 ? 'var(--text-color-mode-1)' : 'var(--text-color-mode-2)';
    root.style.setProperty('--text-color', newTextColor);
    console.log(colorMode);
    console.log(newTextColor);
  }, [colorMode]);

  return (
      <VoiceBotStateProvider>
          <Router>
            <div className="HexagonBackGround">
              <HexagonBackground />
            </div>
            <div className="content-container">
              <div className="logoContainer">
                <img
                  className="Logo"
                  src={logosmall}
                  alt="iSpeakWell logo"
                ></img>
              </div>
              <div>
                <CookieConsent
                  location="bottom"
                  buttonText="I understand"
                  cookieName="iSpeakWellCookieName"
                  style={{ background: "#2B373B" }}
                  buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                  expires={150}
                >
                  This website uses cookies to enhance the user experience.
                </CookieConsent>
              </div>
              <TokenProvider>
                <ScrollToTop />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/cookie-policy" element={<CookiePolicy />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/tokens" element={<Tokens />} />
                  <Route path="/careers" element={<Careers />} />
                  <Route path="/contactus" element={<Contactus />} />
                  <Route path="/accountdeleted" element={<AccountDeleted />} />
                  <Route path="/password-reset" element={<PasswordReset />} />
                  <Route path="/projectsummary" element={<SlideShow />} />
                  <Route path="*" element={<NotFound />} />
                  <Route
                    path="/menu"
                    element={
                      <ProtectedRoute>
                        <Menu />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/voicebot"
                    element={
                      <ProtectedRoute>
                        <VB />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/termsofservice"
                    element={
                      <ProtectedRoute>
                        <TermsofService />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/privacypolicy"
                    element={
                      <ProtectedRoute>
                        <PrivacyPolicy />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/purchase"
                    element={
                      <ProtectedRoute>
                        <Purchase />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/pageexpired"
                    element={
                      <ProtectedRoute>
                        <PageExpired />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/paymentsuccessful"
                    element={
                      <ProtectedRoute>
                        <PaymentSuccessful />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/paymentnotsuccessful"
                    element={
                      <ProtectedRoute>
                        <PaymentNotSuccessful />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/resumerevisor"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/interview-practice"
                    element={
                      <ProtectedRoute>
                        <InterviewPractice />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/conversationpractice"
                    element={
                      <ProtectedRoute>
                        <ConversationPractice />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute>
                        <Admin />
                      </ProtectedRoute>
                    }
                  />
                </Routes>
              </TokenProvider>
            </div>
          </Router>
        </VoiceBotStateProvider>
  );
};

export default App;
