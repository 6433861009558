import React, { useState, useEffect, useCallback, useMemo } from "react";
import Papa from "papaparse";
import axios from "axios";
import Spinner from "./Spinner";
import "./Vocabulary.css";

function Vocabulary({ vocabulary, level }) {
  const [vocabulary30K, setVocabulary30K] = useState([]);
  const [filteredVocabulary30K, setFilteredVocabulary30K] = useState([]);
  const [definitions, setDefinitions] = useState({});
  const [loadingWord, setLoadingWord] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 10;

  const getCurrentDate = () => {
    const now = new Date();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const year = now.getFullYear().toString().slice(-2);
    return `${month}/${day}/${year}`;
  };

  // Fetch vocabulary data and transform it
  useEffect(() => {
    Papa.parse("/data/Vocabulary30K.csv", {
      download: true,
      complete: (results) => {
        const data = results.data.slice(1);
        const transformedData = data.map((row) => ({
          Level: row[0],
          Word: row[1],
          Exposure: row[2],
          Seen: row.slice(3).join(" "),
          Count: parseInt(row[3]) || 0,
        }));
        setVocabulary30K(transformedData);
      },
    });
  }, []);

  // Handle word click to fetch definitions with cancellation support
  const handleVocabularyClick = useCallback(
    async (word) => {
      if (definitions[word]) return;

      const wordData = vocabulary30K.find((item) => item.Word === word);
      if (wordData && (wordData.Exposure === "no exposure" || wordData.Exposure === "never seen")) {
        const currentDate = getCurrentDate();
        wordData.Exposure = `First Exposure: ${currentDate}`;
        wordData.Seen = `Last Exposure: ${currentDate}`;
        wordData.Count = 1;
        setVocabulary30K((prevVocabulary) =>
          prevVocabulary.map((item) =>
            item.Word === word ? { ...item, Exposure: wordData.Exposure, Seen: wordData.Seen, Count: wordData.Count } : item
          )
        );
      }

      setLoadingWord(word);

      const controller = new AbortController(); // Create AbortController
      try {
        const response = await axios.get(`https://transcript.ispeakwell.ca/word_definition?word=${word}`, {
          signal: controller.signal,
        });
        const wordDefinitions = response.data.definitions;
        setDefinitions((prevDefs) => ({
          ...prevDefs,
          [word]: wordDefinitions,
        }));
      } catch (error) {
        if (error.name !== "AbortError") {
          console.error("Error fetching definition:", error);
          setDefinitions((prevDefs) => ({
            ...prevDefs,
            [word]: ["Definition not found or error occurred."],
          }));
        }
      } finally {
        setLoadingWord(null);
      }

      return () => controller.abort(); // Cleanup on unmount or word change
    },
    [definitions, vocabulary30K] // Keep the dependency list minimal to prevent unneeded re-renders
  );

  // Filter vocabulary based on selected level
  useEffect(() => {
    const filtered = level ? vocabulary30K.filter((item) => item.Level === level) : [];
    setFilteredVocabulary30K(filtered);
    // Ensure currentPage does not reset when changing the level
  }, [level, vocabulary30K]);

  // Memoize the paginated vocabulary to optimize rendering
  const paginatedVocabulary = useMemo(() => {
    const startIndex = currentPage * itemsPerPage;
    return filteredVocabulary30K.slice(startIndex, startIndex + itemsPerPage);
  }, [filteredVocabulary30K, currentPage]);

  const totalPages = Math.ceil(filteredVocabulary30K.length / itemsPerPage);

  // Handle search term and pagination
  const handleSearch = () => {
    const index = filteredVocabulary30K.findIndex((item) => item.Word.toLowerCase() === searchTerm.toLowerCase());
    if (index !== -1) {
      const page = Math.floor(index / itemsPerPage);
      setCurrentPage(page);
    } else {
      alert("Word not found");
    }
    setSearchTerm("");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const firstPage = () => setCurrentPage(0);
  const lastPage = () => setCurrentPage(totalPages - 1);

  // Memoize page numbers for pagination
  const displayedPages = useMemo(() => {
    const pages = [];
    const start = Math.max(0, currentPage - 1);
    const end = Math.min(totalPages - 1, currentPage + 1);

    if (start > 0) {
      pages.push(0); // First page
      if (start > 1) pages.push("..."); // Ellipsis if needed
    }

    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    if (end < totalPages - 1) {
      if (end < totalPages - 2) pages.push("..."); // Ellipsis if needed
      pages.push(totalPages - 1); // Last page
    }

    return pages;
  }, [currentPage, totalPages]);

  return (
    <div>
      {/* Search Input */}
      <div style={{ marginBottom: "20px" }}>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search for a word"
          onKeyDown={handleKeyDown} // Handle Enter key
        />
        <button onClick={handleSearch}>Search</button>
      </div>

      {/* Page Numbers */}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
        <button onClick={firstPage} disabled={currentPage === 0}>
          &lt;
        </button>
        {displayedPages.map((page, index) => (
          <span key={index}>
            {typeof page === "number" ? (
              <button
                onClick={() => handlePageClick(page)}
                style={{
                  margin: "0 5px",
                  backgroundColor: currentPage === page ? "#007BFF" : "#f0f0f0",
                  color: currentPage === page ? "white" : "black",
                  border: "none",
                  padding: "5px 10px",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
              >
                {page + 1}
              </button>
            ) : (
              <span style={{ margin: "0 5px" }}>...</span>
            )}
          </span>
        ))}
        <button onClick={lastPage} disabled={currentPage === totalPages - 1}>
          &gt;
        </button>
      </div>

      {paginatedVocabulary.length > 0 ? (
        paginatedVocabulary.map((item, index) => (
          <div key={index} style={{ marginBottom: "15px" }}>
            <h4>{currentPage * itemsPerPage + index + 1}. {item.Word}</h4> {/* Adjust numbering */}
            <ul style={{ paddingLeft: '20px', listStyleType: 'disc' }}>
              <li>{item.Exposure}</li>
              <li>{item.Seen}</li>
              <li>
                Seen {item.Count} {item.Count === 1 ? "time" : "times"}
              </li>
            </ul>
            <button
              onClick={() => handleVocabularyClick(item.Word)}
              style={{ marginBottom: "10px", cursor: "pointer" }}
            >
              Get Definition
            </button>
            {loadingWord === item.Word && (
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Spinner />
              </div>
            )}
            {definitions[item.Word] && (
              <div style={{ marginTop: "5px", color: "white", fontSize: "14px" }}>
                <strong>Available Definition(s):</strong>
                <ol>
                  {definitions[item.Word].map((def, i) => (
                    <li key={i} style={{ marginBottom: "5px" }}>
                      {i + 1}. {typeof def === "string" ? def : def.definition} {/* Numbering definitions */}
                    </li>
                  ))}
                </ol>
              </div>
            )}
          </div>
        ))
      ) : (
        <p>{level ? "No vocabulary found for this level." : "Select a level."}</p>
      )}

      {/* Page Navigation */}
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "20px" }}>
        <button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 0))} disabled={currentPage === 0}>
          Previous
        </button>
        <span style={{ margin: "0 10px" }}>
          {currentPage + 1} of {totalPages}
        </span>
        <button
          onClick={() =>
            setCurrentPage((prev) => (prev + 1) * itemsPerPage < filteredVocabulary30K.length ? prev + 1 : prev)
          }
          disabled={(currentPage + 1) * itemsPerPage >= filteredVocabulary30K.length}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default Vocabulary;
